
<template>
	<div class="section bg-price" id="planos">
		<div class="container clearfix">
			<div class="row content-row justify-content-between">
				<div class="col heading-block-wrapper">
					<div class="heading-block border-bottom-0 ">
						<h4 class="nott mb-3">Estude com o Pazzei</h4>
						<p>O Pazzei é a plataforma que vai te ajudar a conquistar a aprovação no vestibular de forma eficiente. Confira nossas opções de assinatura e comece a se preparar ainda hoje.</p>
					</div>
					
				</div>

				<div class="col pricing-wrapper">
					<div id="section-pricing" class="page-section p-0 m-0">

						<div id="pricing-switch" class="pricing row align-items-end g-0 col-mb-50 mb-4">

							<div class="col-md-12">

								<div class="pricing-box pricing-box-border-radius" data-animate="fadeIn">
									<div class="pricing-price center">
										<div class="pts-switch-content-left">
											<p class="price-old">De <span>R&dollar; 49,90</span> por</p>
											<span class="price-wrapper">
												<span class="price-unit">R&dollar;</span>
												<span class="price">29,90</span>
												<span class="price-fix">/mês</span>
											</span>
											<span class="price-tenure">*Pagamento mensal</span>
										</div>
										<div class="pts-switch-content-right">
											<p class="price-old">De <span>R&dollar; 240,40</span> por</p>
											<span class="price-wrapper">
												<span class="price-unit">R&dollar;</span>
												<span class="price">19,90</span>
												<span class="price-fix">/mês</span>
											</span>
											<span class="price-desc-wrapper">
												<span class="price-desc">Total de R&dollar; 119,40</span>
												<span class="price-tenure">*Pagamento único</span>
											</span>
										</div>
									</div>
									<div class="pricing-tenure-switcher d-flex align-items-center mb-4 position-relative justify-content-center pb-3" data-container="#pricing-switch">
										<span class="pts-left text-muted">Mensal</span>
										<div class="pts-switcher mx-3">
											<div class="switch">
												<input id="switch-toggle-pricing-tenure" class="switch-toggle switch-toggle-round" type="checkbox">
												<label for="switch-toggle-pricing-tenure" class="mb-0"></label>
											</div>
										</div>
										<span class="pts-right text-muted">Semestral</span>
									</div>
									<div class="pricing-features-wrapper">
										<h6>
											Plano Essencial
										</h6>
										<div class="pricing-features border-0 bg-transparent">
											<ul>
												<li><i class="icon-check-circle text-color-green me-3"></i>Listas de estudo ilimitadas</li>
												<li><i class="icon-check-circle text-color-green me-3"></i>+150 fontes de todo o Brasil</li>
												<li><i class="icon-check-circle text-color-green me-3"></i>+150.000 questões disponíveis</li>
												<li><i class="icon-check-circle text-color-green me-3"></i>Questões resolvidas e comentadas</li>
											</ul>
										</div>
									</div>
									<div class="pricing-action">
										<div class="pts-switch-content-left"><a :href="`${portal_url}cadastro`" target="_blank" class="button button-circle">Quero agora</a></div>
										<div class="pts-switch-content-right"><a :href="`${portal_url}cadastro`" target="_blank" class="button button-circle">Quero agora</a></div>
									</div>
								</div>

							</div>

						</div>

					</div>
				</div>

			</div>
		</div>
	</div>
</template>

<script>
export default {
  name: 'PricesComponent',
  data () {
			return {
				portal_url: process.env.VUE_APP_PORTAL_URL,
			}
		},
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.bg-price {
	padding: 0;
	background-color: #FFFFFF;
	background-size: auto 100%;
	font-family: 'public-sans';
}

.container {
	margin-top: 80px;
	color: var(--gray-700);
}

.content-row {
	justify-content: space-between;
	width: 100%;
	gap: 95px;
	margin: 0;
}

.heading-block-wrapper, .pricing-wrapper {
	padding: 0;
}

.pricing-wrapper {
	display: flex;
	justify-content: flex-end;
}

.pricing-box {
	width: 481px;
	padding: 59px 92px !important;
	border-radius: 24px;
	border: 3px solid var(--primary);
	gap: 36px;
	animation: box-shadow-fade-out 1s;
}

.pricing-box:hover {
	animation: box-shadow-fade-in 1s;
	border-color: var(--secondary);
	box-shadow: 0px 0px 0px 4px var(--secondary);
}

@keyframes box-shadow-fade-out {
	from {
		border-color: var(--secondary);
		box-shadow: 0px 0px 0px 4px var(--secondary);
	}
	to {
		border: 3px solid var(--primary);
	}
}

@keyframes box-shadow-fade-in {
	from {
		border: 3px solid var(--primary);
	}
	to {
		border-color: var(--secondary);
		box-shadow: 0px 0px 0px 4px var(--secondary);
	}
}

.pts-switch-content-left, .pts-switch-content-right {
	display: flex;
	flex-direction: column;
	align-items: center;
	font-family: 'public-sans';
	color: var(--gray-700);
	gap: 0.5rem;
}

.pricing-price .price-old {
	font-size: 18px;
	font-weight: 400;
	line-height: 32px;
	letter-spacing: 0em;
	text-align: center;
	margin-bottom: 0;
}

.pricing-price .price-old span {
	text-decoration-line: line-through;
	text-decoration-color: var(--gray-700);
}

.price-wrapper {
	display: flex;
	justify-content: center;
	font-family: 'public-sans';
	font-weight: 700;
}

.pricing-price .price-unit {
	font-size: 28px;
	line-height: 28px;
	letter-spacing: 0em;
	text-align: center;
}

.pricing-price .price {
	font-size: 64px;
	line-height: 48px;
	letter-spacing: 0em;
	text-align: center;
}

.pricing-price .price-fix {
	display: flex;
	align-items: flex-end;
	font-size: 28px;
	line-height: 28px;
	letter-spacing: 0em;
	text-align: center;
}

.pricing-price .price-desc {
	font-size: 18px;
	font-weight: 400;
	line-height: 32px;
	letter-spacing: 0em;
	text-align: center;
}

.pricing-price .price-tenure {
	margin-top: 0.25rem;
	font-size: 11px;
	font-weight: 400;
	line-height: 12px;
	letter-spacing: 0em;
	text-align: center;
	color: var(--gray-400);
}

.pricing-tenure-switcher {
	margin-top: 24px;
}

.fw-bold {
	font-weight: 400 !important;
}

.pricing-features-wrapper h6 {
	font-size: 20px;
	font-weight: 600;
	line-height: 30px;
	letter-spacing: 0em;
	margin-bottom: 18px;
	text-align: center;
}	

.pricing-features {
	padding: 0;
}

.pricing-features li {
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
	letter-spacing: 0em;
	text-align: left;
	padding: 0;
}

.pricing-action {
	margin-top: 2.25rem;
}

.pricing-box-border-radius{
	border-radius: 25px;
}

.pricing-title h6{
	font-weight: 600;
	font-size: 18px;
}

.heading-block {
	margin-top: 80px;
}

.heading-block > h4 {
	font-family: 'public-sans';
	font-weight: 700;
	font-size: 24px;
	text-align: left;
	letter-spacing: -0.02em;
}

.heading-block > p {
	font-size: 18px;
	font-weight: 400;
	line-height: 24px;
	letter-spacing: 0em;
	text-align: left;
}

.button {
	text-transform: none;
}

@media (max-width: 670px) {
	.content-row {
		gap: 0;
	}

	.heading-block-wrapper, .heading-block {
		width: 100%;
	}

	.heading-block {
		height: auto;
		margin-top: 15px;
	}

	.pricing-wrapper {
		justify-content: center;
	}

	.pricing-box {
		width: 100%;
		height: auto;
		margin: 0 auto;
		padding: 1rem !important;
		border-radius: 24px;
		border: 3px solid var(--primary);
		gap: 36px;
	}
}

@media (min-width: 798px) and (max-width: 1367px) {
	.container {
		max-width: max-content;
		margin: 80px 214px 0;
	}

	.heading-block {
		width: 362px;
		height: 343px;
	}
}
	
@media (min-width: 992px) {
  .heading-block > h4 {
    font-size: 36px;
    line-height: 44px;
  }
}
</style>
