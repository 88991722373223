
<template>
	<div class="section bg-transparent" id="diferenciais">
		<div class="row heading-row bg-color-secondary border-bottom-0 center mx-auto mb-0">
			<div class="col-5 heading-text-wrap">
				<h4 class="text-white">
					Comece a estudar agora.<br>
					Tenha 7 dias grátis para testar.
				</h4>
			</div>
			<div class="col-7 button-wrap">
				<a 
					:href="`${portal_url}entrar`"
					target="_blank"
					class="button bg-color-white text-secondary-2 button-rounded nott button-circle"
				>Acesse o Pazzei</a>
			</div>
		</div>
		<div class="custom-row">
			<div class="row justify-content-center align-items-start">
				<div class="feature-box-wrapper bg-color-primary col-6 col-md-4 col-lg-4">
					<div class="feature-box fbox-center border-0" data-animate="fadeIn">
						<div class="fbox-icon">
							<img src="../assets/icons/icon_questoes.svg" alt="ícone questões resolvidas, comentadas e atualizadas">
						</div>
						<div class="fbox-content">
							<p class="text-white">Milhares de questões resolvidas, comentadas e atualizadas</p>
						</div>
					</div>
				</div>
				<div class="feature-box-wrapper bg-color-light-blue col-6 col-md-4 col-lg-4">
					<div class="feature-box fbox-center border-0" data-animate="fadeIn">
						<div class="fbox-icon">
							<img src="../assets/icons/icon_enem.svg" alt="ícone principais vestibulares">
						</div>
						<div class="fbox-content">
							<p class="text-white">Questões do ENEM e dos principais vestibulares e exames do Brasil</p>
						</div>
					</div>
				</div>
				<div class="feature-box-wrapper bg-color-yellow col-6 col-md-4 col-lg-4">
					<div class="feature-box fbox-center border-0" data-animate="fadeIn">
						<div class="fbox-icon">
							<img src="../assets/icons/icon_listas.svg" alt="ícone listas">
						</div>
						<div class="fbox-content">
							<p class="text-gray-700">Crie suas próprias listas de exercício de acordo com seus estudos</p>
						</div>
					</div>
				</div>
				<div class="feature-box-wrapper bg-color-yellow col-6 col-md-4 col-lg-4">
					<div class="feature-box fbox-center border-0" data-animate="fadeIn">
						<div class="fbox-icon">
							<img src="../assets/icons/icon_provas.svg" alt="ícone provas">
						</div>
						<div class="fbox-content">
							<p class="text-gray-700">Provas prontas de exames anteriores para você treinar</p>
						</div>
					</div>
				</div>
				<div class="feature-box-wrapper bg-color-primary col-6 col-md-4 col-lg-4">
					<div class="feature-box fbox-center border-0" data-animate="fadeIn">
						<div class="fbox-icon">
							<img src="../assets/icons/icon_assuntos.svg" alt="ícone assuntos">
						</div>
						<div class="fbox-content">
							<p class="text-white">Estude os assuntos que mais caem por matéria, ano, prova e dificuldade</p>
						</div>
					</div>
				</div>
				<div class="feature-box-wrapper bg-color-secondary col-6 col-md-4 col-lg-4">
					<div class="feature-box fbox-center border-0" data-animate="fadeIn">
						<div class="fbox-icon">
							<img src="../assets/icons/icon_resultados.svg" alt="ícone resultados">
						</div>
						<div class="fbox-content">
							<p class="text-white">Acompanhe seus resultados após cada lista</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
  name: 'FeaturesComponent',
  data () {
		return {
			portal_url: process.env.VUE_APP_PORTAL_URL,
		}
	},
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.section {
	padding: 0;
}

.heading-row {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 196px;
}

.heading-text-wrap {
	display: flex;
	justify-content: right;
	align-items: center;
}

h4 {
	margin: 0;
	font-family: 'public-sans';
	font-weight: 700;
}

.button {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 198px;
	height: 40px;
	padding: 0 32px;
	border: 2px;
	font-size: 18px;
	font-style: normal;
	font-weight: 600;
	line-height: 20px; /* 111.111% */
}

.feature-box-wrapper {
	--bs-gutter-x: 0;
	padding: 0 !important;
}

.feature-box {
	margin: 0;
	height: 258px;
	padding: 24px, 20px;
	gap: 6px;
	justify-content: center;
}

.fbox-content {
	flex-grow: 0;
}

.fbox-content p {
	font-size: 16px;
	font-weight: 500;
	line-height: 24px !important;
	text-align: center;
	max-width: 280px;
}

.fbox-icon {
	width: 90px;
	height: 90px;
	padding: 0;
	margin-bottom: 0 !important;
	border-radius: 0;
	display: flex;
	justify-content: center;
	align-items: center;
}

@media (max-width: 670px) {
	.row {
		padding: 0;
	}

	.heading-row {
		flex-direction: column;
		gap: .5rem;
	}

	.heading-text-wrap, .button-wrap {
		width: 100%;
		justify-content: center;
		padding: 0;
	}

	.button-wrap {
		display: flex;
	}
}

@media (min-width: 992px) {
	h4 {
		text-align: left;
		font-size: 30px;
		font-weight: 700;
		line-height: 38px;
	}
}
</style>
