<template>
  <div class="section" id="beneficios">
    <div class="container clearfix">
      <div class="row">
        <div class="col-12 col-md-4">
          <div class="heading-block border-bottom-0 mb-3">
            <h4 class="nott mb-0">Por dentro do Pazzei</h4>
          </div>

          <div class="accordion accordion-flush" id="accordionExample">
            <div class="accordion-item">
              <h5 class="mb-0" id="headingZero">
                <button @click="isShowCollapse0 = true; isShowCollapse1 = isShowCollapse2 = isShowCollapse3 = false;"
                  class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseZero"
                  aria-expanded="true" aria-controls="collapseZero">
                  <i class="icon-check"></i> Lista de exercícios
                </button>
              </h5>
              <div id="collapseZero" class="accordion-collapse collapse show" aria-labelledby="headingZero"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  Monte e resolva listas de exercícios utilizando filtros por matérias, instituições, tipo de questão,
                  entre outros.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h5 class="mb-0" id="headingOne">
                <button @click="isShowCollapse1 = true; isShowCollapse0 = isShowCollapse2 = isShowCollapse3 = false;"
                  class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                  data-bs-target="#collapseOne" aria-controls="collapseOne">
                  <i class="icon-check"></i> Banco de provas
                </button>
              </h5>
              <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  Acesse e resolva simulados de exames anteriores do seu vestibular e ENEM.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h5 class="mb-0" id="headingThree">
                <button @click="isShowCollapse2 = true; isShowCollapse0 = isShowCollapse1 = isShowCollapse3 = false;"
                  class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                  data-bs-target="#collapseThree" aria-controls="collapseThree">
                  <i class="icon-check"></i> Módulo Enem
                </button>
              </h5>
              <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  Treine para o Enem com simulados dos assuntos mais cobrados na prova, de maneira simplificada e no seu ritmo.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  Compare seu desempenho em cada lista e entre suas listas resolvidas, além disso, acompanhe a sua
                  performance em cada matéria.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h5 class="mb-0" id="headingFive">
                <button @click="isShowCollapse3 = true; isShowCollapse0 = isShowCollapse1 = isShowCollapse2 = false;"
                  class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                  data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                  <i class="icon-check"></i> Questões resolvidas e comentadas
                </button>
              </h5>
              <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  Tenha acesso a mais de 150 mil questões, dos principais vestibulares e Enem resolvidas e comentadas
                  pelo nosso time pedagógico.
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 col-md-8 ps-md-5 images-col">
          <div v-if="isShowCollapse0">
            <img src="../assets/accordion-lista-exercicios.png" class="rounded"
              alt="Print do Sistema Pazzei, referente a lista de exercícios.">
          </div>
          <div v-if="isShowCollapse1">
            <img src="../assets/accordion-banco-provas.png" class="rounded"
              alt="Print do Sistema Pazzei, referente ao banco de provas.">
          </div>
          <div v-if="isShowCollapse2">
            <img src="../assets/accordion-modulo-enem.png" class="rounded"
              alt="Print do Sistema Pazzei, referente ao módulo ENEM.">
          </div>
          <div v-if="isShowCollapse3">
            <img src="../assets/accordion-questoes.png" class="rounded"
              alt="Print do Sistema Pazzei, refernte as questões resolvidas e comentadas.">
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'ContentTabComponent',

  data: function () {
    return {
      isShowCollapse0: true,
      isShowCollapse1: false,
      isShowCollapse2: false,
      isShowCollapse3: false,
    };
  },
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.row {
  --bs-gutter-x: 0;
  --bs-gutter-y: 0;
}

.heading-block > h4 {
  font-family: 'public-sans';
	font-weight: 700;
  letter-spacing: -0.02em;
  font-size: 24px;
  text-align: center;
}

.accordion {
  display: flex;
	flex-direction: column;
	gap: 0.5rem;
}

.accordion-item i {
  color: #FF0094;
  padding-right: 10px;
}

.accordion-item .accordion-button {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
}

@media (min-width: 992px) {
  .section {
    background: linear-gradient(to right, var(--white) 64.86091%, var(--light-blue-400) 35.13909%);
  }

	.container {
		padding-left: 30px;
	}

	.heading-wrap {
		max-width: 553px;
		margin: 99px 0 0 65px;
	}

  .heading-block > h4 {
    font-size: 36px;
    line-height: 44px;
    text-align: left;
  }

  .images-col {
    margin-top: 24px;
  }
}
</style>
